<template>
  <form @submit.prevent="signIn">
    <div
      class="d-flex mb-15 mb-xl-24"
      :class="isModal ? 'justify-content-center' : ' justify-content-between justify-content-sm-center'"
    >
      <AppButton
        :class="isModal ? 'me-15' : 'me-sm-15'"
        type="button"
        :active="isPhone === true"
        :variant="BtnVariant.Tertiary"
        @click="changeLoginType(true)"
      >
        По телефону
      </AppButton>
      <AppButton
        type="button"
        :active="isPhone === false"
        :variant="BtnVariant.Tertiary"
        @click="changeLoginType(false)"
      >
        По E-Mail
      </AppButton>
    </div>
    <AppInput
      v-if="isPhone"
      v-model="$v.login.$model"
      name="phone"
      type="tel"
      class="w-100 mb-15 mb-xl-24"
      maska="+7 (F##) ###-##-##"
      :maska-tokens="{
        F: { pattern: '[012345679]' },
      }"
      label="Номер телефона"
      error-message="Номер телефона не корректен"
      :is-invalid="$v.login.$error"
    />
    <AppInput
      v-else
      v-model="$v.login.$model"
      name="email"
      type="email"
      class="w-100 mb-15 mb-xl-24"
      label="E-Mail"
      error-message="E-Mail не корректен"
      :is-invalid="$v.login.$error"
    />
    <AppInput
      v-model="$v.password.$model"
      class="w-100"
      type="password"
      label="Пароль"
      :error-message="$v.password.required.$error ? 'Введите пароль' : 'Пароль должен содержать не менее 8 любых символов!'"
      :is-invalid="$v.password.$error"
    />
    <div class="text-end">
      <AppButton
        class="mb-15"
        to="/forgotpass"
        :variant="BtnVariant.Link"
      >
        Забыли пароль?
      </AppButton>
    </div>
    <AppButton
      class="fw-bold w-100"
      :class="{'mb-20' : isModal}"
      :disabled="loading"
      :variant="BtnVariant.Primary"
    >
      Войти
    </AppButton>
  </form>
  <div>
    <p class="text-center mb-5">
      У вас ещё нет аккаунта?
    </p>
    <AppButton
      class="fw-bold w-100"
      to="/signup"
      :variant="BtnVariant.Tertiary"
    >
      Зарегистрироваться
    </AppButton>
  </div>
</template>

<script setup lang="ts">
import { BtnVariant } from "~/composables/enums/BtnVariant";
import AppInput from "~/components/inputs/AppInput.vue";
import { and, email, minLength, or, required } from "@vuelidate/validators";
import { phone } from "~/composables/validator";
import { useClient } from "~/stores/client";
import { useVuelidate } from "@vuelidate/core";

const props = defineProps({
  isModal: { type: Boolean, default: false }
});

const emits = defineEmits(['login']);

const client = useClient();
const router = useRouter();
const { $toast } = useNuxtApp();

//-----STATE-----\\
const isPhone = ref<boolean>(true);
const loading = ref<boolean>(false);
const login = ref<string>('');
const password = ref<string>('');

//-----COMPUTED-----\\
const $v = useVuelidate(
  {
    login: {
      required,
      or: or(
        and(() => isPhone.value, phone),
        and(() => !isPhone.value, email),
      ),
    },
    password: {
      required,
      minLength: minLength(8)
    }
  }, {
    login,
    password
  });

//-----METHODS-----\\
function changeLoginType(newIsPhone: boolean) {
  isPhone.value = newIsPhone;
  login.value = '';
}
async function signIn() {
  $v.value.$touch();
  if ($v.value.$invalid) {
    return;
  }
  loading.value = false;

  client.signIn(
    login.value,
    password.value
  ).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code !== 200) {
      $toast.error(response.error.clientMessage??'Ошибка');
      return;
    }

    if (props.isModal) {
      emits('login');
      return;
    }

    if (client.role < 0) {
      router.push('/admin/dashboard');
    } else if (client.role === 0) {
      router.push('/applicant/responses');
    } else {
      router.push(`/company/${client.getActiveCompany.id}/vacancies${client.getActiveCompany.isVacancyCreated ? '' : '/preview/responses'}`);
    }
  }).finally(() => {
    loading.value = false;
  });
}

</script>

<style scoped lang="scss">

</style>